import {
  createAutoRecommendation,
  CreateAutoRecommendationPayload,
  createRecommendation,
  CreateRecommendationPayload,
  deleteRecommendation,
  getRecommendation,
  sendRecommendation,
  updateRecommendation,
  withdrawRecommendations
} from '@/api';
import {
  AddRecommendationMutationResult,
  RecommendationViewResponse
} from '@/types/recommendation';
import { snakeToCamelCase, useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery, UseQueryOptions } from 'react-query';

interface UseCreateRecommendationPayload {
  categoryId?: string;
}

export const useRecommendation = (
  id: string,
  options: UseQueryOptions<
    RecommendationViewResponse,
    unknown,
    RecommendationViewResponse,
    string[]
  >
) =>
  useQuery(['recommendationProducts', id], () => getRecommendation(id), {
    ...options,
    select: (data) => {
      return {
        ...data,
        recommendationTemplate: data.recommendationTemplate
          ? {
              ...data.recommendationTemplate,
              attributes: data.recommendationTemplate.attributes.map(
                ({ code, isVisible }) => ({
                  // FIXME: I hate this soo much ;_; - until we migrate away from renaming responses keys in the admin
                  // we will have to keep this
                  code: snakeToCamelCase(code),
                  isVisible
                })
              )
            }
          : null
      };
    }
  });

// fixme: Separate in two hooks to avoid confusing behavior?
export const useCreateRecommendation = ({
  insuranceId,
  categoryId,
  locationId
}: Omit<CreateRecommendationPayload, 'businessVertical'>) =>
  useInvalidateOnSuccessMutation<
    AddRecommendationMutationResult,
    unknown,
    UseCreateRecommendationPayload,
    unknown
  >('location', (payload: UseCreateRecommendationPayload) => {
    return createRecommendation({
      locationId,
      insuranceId,
      categoryId: categoryId || payload.categoryId
    });
  });

export const useCreateAutoRecommendation = (locationId: string) =>
  useInvalidateOnSuccessMutation(
    ['location', locationId],
    ({ insuranceId, categoryId }: CreateAutoRecommendationPayload) =>
      createAutoRecommendation({
        locationId,
        insuranceId,
        categoryId
      })
  );

export const useDeleteRecommendations = () =>
  useInvalidateOnSuccessMutation('location', (ids: string[]) =>
    deleteRecommendation(ids)
  );

export const useWithdrawRecommendations = () =>
  useInvalidateOnSuccessMutation('location', (ids: string[]) =>
    withdrawRecommendations(ids)
  );

export const useUpdateRecommendationsNotes = (id: string) =>
  useInvalidateOnSuccessMutation(
    ['recommendationProducts', id],
    (notes: string[]) => updateRecommendation({ id, notes })
  );

export const useSendRecommendations = () =>
  useInvalidateOnSuccessMutation('location', (ids: string[]) =>
    sendRecommendation(ids)
  );

export const useUpdateRecommendationAttributes = (id: string) =>
  useInvalidateOnSuccessMutation(
    ['recommendationProducts', id],
    ({
      recommendationTemplate
    }: {
      recommendationTemplate: {
        version: number;
        attributes: { code: string; isVisible: boolean }[];
      };
    }) => updateRecommendation({ id, recommendationTemplate })
  );

export const useUpdateRecommendationSearchCriteria = (id: string) =>
  useInvalidateOnSuccessMutation(
    ['recommendationProducts', id],
    (searchCriteria: string[]) =>
      updateRecommendation({ id, searchCriteria, userSearchCriteria: null })
  );
