import { Loader, PdfRecommendation } from '@/components';
import { CategoryLabelMap } from '@/enums';
import {
  useInsurance,
  useInsuranceProducts,
  useRecommendation,
  useUpdateRecommendationsNotes
} from '@/services';
import { InsuranceProductWithCarrier } from '@/types/insurance';
import { RecommendationViewResponse } from '@/types/recommendation';
import { formatMoney, useGoBack, useModal } from '@/utils';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space } from 'antd';
import { groupBy, map, pipe, prop } from 'ramda';
import { useParams } from 'react-router-dom';
import { PageForm } from './PageForm';
import { SearchCriteria } from './SearchCriteria';
import './styles.scss';
import { PDFIcon } from '@/icons/PDFIcon';

type ProductResult = Pick<
  InsuranceProductWithCarrier,
  | 'name'
  | 'carrier'
  | 'categoryId'
  | 'businessVertical'
  | 'insuranceProductId'
  | 'discountsOptions'
  | 'deductiblesOptions'
  | 'insuranceSumsOptions'
  | 'disabledFields'
  | 'isArchived'
>;

export const formatFuzzyPrice = (value: string | number) =>
  typeof value === 'number' ? formatMoney(value) : value;

const makeProducts = pipe(
  map(
    ({
      carrier,
      categoryId,
      businessVertical,
      name,
      insuranceProductId,
      discountsOptions,
      deductiblesOptions,
      insuranceSumsOptions,
      disabledFields,
      isArchived
    }: ProductResult) => ({
      categoryId,
      businessVertical,
      name,
      logo: carrier.logoUrl,
      carrierName: carrier.name,
      insuranceProductId,
      discountsOptions,
      deductiblesOptions,
      insuranceSumsOptions,
      disabledFields,
      isArchived
    })
  ),
  groupBy(prop('categoryId'))
);

const sortRecommendationProducts = (
  recommendation: RecommendationViewResponse
) => ({
  ...recommendation,
  recommendationProducts: recommendation.recommendationProducts.sort(
    (a, b) => a.order - b.order
  )
});

const GENERATE_PDF_CATEGORIES = [
  'LI',
  'CO',
  'LE',
  'FL',
  'CY',
  'EL',
  'MI',
  'DO'
];

export const RecommendationPage = () => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal
  } = useModal();
  const { recommendationId } = useParams<{ recommendationId: string }>();
  const { data: insuranceProducts, isLoading: areProductsLoading } =
    useInsuranceProducts({
      select: makeProducts
    });
  const { data, isLoading } = useRecommendation(recommendationId, {
    select: sortRecommendationProducts
  });

  const { data: insurance, isLoading: isInsuranceLoading } = useInsurance(
    data?.insuranceId as string,
    {
      enabled: Boolean(data?.insuranceId)
    }
  );

  const searchState = useModal();
  const goBack = useGoBack();
  const updateRecommendationsNotesMutation =
    useUpdateRecommendationsNotes(recommendationId);

  const handleNotesBlur = (notes: Record<string, string>) =>
    updateRecommendationsNotesMutation.mutate({
      ...data?.notes,
      ...notes
    });

  if (isLoading || areProductsLoading || !data || isInsuranceLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="flex justify-between">
        <h1>
          {CategoryLabelMap[data.categoryId]} Recommendation for{' '}
          {data.locationName}
        </h1>
        {GENERATE_PDF_CATEGORIES.includes(data.categoryId) && (
          <Button icon={<PDFIcon />} onClick={openModal}>
            PDF Preview
          </Button>
        )}
      </div>
      <div className="textarea-container">
        <Button type="text" onClick={goBack}>
          <LeftCircleOutlined /> Go Back
        </Button>
        <Input.TextArea
          disabled={!!data.convertedInsuranceId}
          name="note-en"
          defaultValue={data.notes?.en}
          onBlur={(e) => handleNotesBlur({ en: e.target.value })}
          placeholder="English"
          autoSize={{ minRows: 6, maxRows: 6 }}
        />
        <Input.TextArea
          disabled={!!data.convertedInsuranceId}
          name="note-de"
          defaultValue={data.notes?.de}
          onBlur={(e) => handleNotesBlur({ de: e.target.value })}
          placeholder="German"
          autoSize={{ minRows: 6, maxRows: 6 }}
        />
      </div>
      <SearchCriteria
        categoryId={data.categoryId}
        isOpen={searchState.isOpen}
        onClose={searchState.close}
        initialValues={data.searchCriteria ?? undefined}
      />
      <PageForm
        // TODO: pass the recommendation object instead of all these props
        locationId={data.locationId}
        companyId={data.companyId}
        categoryId={data.categoryId}
        businessVertical={data.businessVertical}
        recommendationTemplate={data.recommendationTemplate}
        recommendationProducts={data.recommendationProducts}
        searchCriteria={data.searchCriteria}
        insurance={insurance}
        products={
          insuranceProducts
            ? insuranceProducts[data.categoryId as string]
            : undefined
        }
        convertedInsuranceId={data.convertedInsuranceId}
        onSearch={searchState.open}
        openSearchCriteria={searchState.open}
        locationType={data.locationType}
        locationSubtypes={data.locationSubtypes}
        riskAddress={data.address}
      />
      <Modal
        centered
        visible={isModalOpen}
        onCancel={closeModal}
        width={1050}
        footer={false}
        title={
          <Space>
            <PDFIcon />
            <span>PDF preview</span>
          </Space>
        }
      >
        <PdfRecommendation
          recommendationId={recommendationId}
          // missing company name most likely won't happen - just a fallback
          companyName={data.companyName ?? '[company-name-missing]'}
          categoryId={data.categoryId}
        />
      </Modal>
    </div>
  );
};
